import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Button, PageHeader } from 'antd';
import axios from 'axios';

import DetailsCard from "./DetailsCard";
import AreaInfoCard from "./AreaInfoCard";
import BlockAndBuildingInfoCard from "./BlockAndBuildingInfoCard";
import LoadingIcon from "../LoadingIcon";
import MapCard from "./MapCard";
import { getBackEndUrl } from "../BackEndUrl";
import { CopyOutlined } from "@ant-design/icons";

function Property() {
  let { id } = useParams();
  const navigate = useNavigate();
  const initialPropertyData = {
    loaded: false,
    address: {
      propertyName: '',
      firstLineOfAddress: '',
      borough: '',
      city: '',
      country: '',
      postcode: '',
    },
    areaInfo: {
      localAuthority: '',
      ward: '',
      constituency: '',
      region: '',
      country: '',
    },
    blockAndBuildingInfo: {
      blockUprn: '',
      blockProperties: [],
    },
  };
  const [propertyData, setPropertyData] = useState(initialPropertyData);

  if (!propertyData.loaded) {
    let backEndUrl = getBackEndUrl();

    axios.get(backEndUrl + '/property/' + id).then(response => {
      const addressData = response.data.address;
      const areaInfoData = response.data.area_info;
      const blockInfoData = response.data.block_info;

      setPropertyData({
        loaded: true,
        address: {
          propertyName: addressData.property_name,
          uprn: addressData.uprn,
          firstLineOfAddress: addressData.first_line_of_address,
          borough: addressData.borough,
          city: addressData.city,
          country: areaInfoData.country,
          postcode: addressData.postcode,
        },
        areaInfo: {
          localAuthority: areaInfoData.local_authority,
          ward: areaInfoData.ward,
          constituency: areaInfoData.constituency,
          region: areaInfoData.region,
          country: areaInfoData.country,
        },
        blockAndBuildingInfo: {
          blockUprn: blockInfoData.block_uprn,
          blockProperties: blockInfoData.block_properties,
        },
      });
    });
  }

  return (
    <div className="App-property">
      <header className="App-property-header">
        <PageHeader
          className="App-property-page-header"
          onBack={ () => navigate('/') }
          title="Search"
        />
      </header>
      { propertyData.loaded
        ? (
          <div>
            <h2>
              { propertyData.address.propertyName }
              &nbsp;
              <Button
                id="copyUprnBig"
                type="secondary"
                shape="round"
                icon={ <CopyOutlined /> }
                size="large"
                onClick={() => {navigator.clipboard.writeText(propertyData.address.uprn)}}
              >
                Copy UPRN
              </Button>
            </h2>
            <div className="App-property-body">
              <MapCard
                firstLineOfAddress={ propertyData.address.firstLineOfAddress }
                postcode={ propertyData.address.postcode }
              />
              <DetailsCard address={ propertyData.address }/>
              <AreaInfoCard areaInfo={ propertyData.areaInfo }/>
              <BlockAndBuildingInfoCard
                propertyUprn={ propertyData.address.uprn }
                blockInfo={ propertyData.blockAndBuildingInfo }
              />
            </div>
          </div>
        )
        : <LoadingIcon />
      }
    </div>
  );
}

export default Property;
