import React from "react";

export default function MapCard({ firstLineOfAddress, postcode }) {
  const apiKey = 'AIzaSyAAll53FybOjNty7DT7hFzNkcCcTY-8CWM';
  const searchTerm = firstLineOfAddress + '+' + postcode;
  const sourceUrl = `https://www.google.com/maps/embed/v1/place?key=${ apiKey }&q=${ searchTerm }`

  return <div className="App-property-map">
    <iframe
      width="450"
      height="325"
      style={{ border: 0 }}
      loading="lazy"
      src={ sourceUrl }
    >
    </iframe>
  </div>;
}
