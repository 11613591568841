import { Link } from "react-router-dom";
import Route from "../property/Route";
import { connect } from "react-redux";
import { Menu } from 'antd';

function Results({ searchResults, selectedResult }) {
  const searchResultsDisplay = searchResults.map(
    (searchResult) => {
      let key = 'list-item-' + searchResult.listItemNumber;
      let isSelected = searchResult.listItemNumber === selectedResult.listItemNumber;

      let text = isSelected
        ? <span className="ant-menu-item-active ant-menu-item-only-child">{ searchResult.address }</span>
        : <span>{ searchResult.address }</span>
      ;
      let path = Route(searchResult.id);

      return <Menu.Item key={ key } id={ searchResult.id }><Link to={ path }>{ text }</Link></Menu.Item>;
    }
  );

  return (
    <Menu selectable={false}>
        { searchResultsDisplay }
    </Menu>
  );
}

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
    selectedResult: state.selectedResult,
  };
};

export default connect(mapStateToProps)(Results);
