import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Input as AntdInput } from 'antd';

import PropertyRoute from "../property/Route";
import {
  updateSearchTerm,
  fetchSearchResults,
  selectResultAbove,
  selectResultBelow
} from "../../actions";

function Input({
  searchTerm,
  updateSearchTerm,
  fetchSearchResults,
  searchResults,
  selectedResult,
  selectResultAbove,
  selectResultBelow
}) {
  const navigate = useNavigate();
  const { Search } = AntdInput;

  const onChange = (event) => {
    let inputValue = event.target.value;

    updateSearchTerm(inputValue);
    fetchSearchResults(inputValue);
  };

  const onKeyDown = (event) => {
    if (searchResults.length > 0) {
      switch (event.key) {
        case 'Enter':
          navigate(PropertyRoute(selectedResult.id));
          break;
        case 'ArrowUp':
          selectResultAbove(selectedResult.listItemNumber, searchResults);
          break;
        case 'ArrowDown':
          selectResultBelow(selectedResult.listItemNumber, searchResults);
          break;
        default:
        // Do nothing in the default case
      }
    }
  };

  return (<Search
    autoFocus={ true }
    size="large"
    onKeyDown={ onKeyDown }
    onChange={ onChange }
    value={ searchTerm }
    placeholder="Search for an address"
  />);
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchTerm,
    searchResults: state.searchResults,
    selectedResult: state.selectedResult,
  };
};

export default connect(mapStateToProps, {
  updateSearchTerm,
  fetchSearchResults,
  selectResultAbove,
  selectResultBelow,
})(Input);
