const selectedResultReducer = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_SEARCH_RESULTS':
      const searchResults = action.payload.searchResults;

      const formattedSearchResults = Object.entries(searchResults).map(datum => {
        let listItemNumber = datum[0];
        let propertyData = datum[1];
        return {
          listItemNumber: parseInt(listItemNumber),
          id: propertyData.id,
          address: propertyData.address
        };
      });

      return formattedSearchResults[0] !== undefined ? formattedSearchResults[0] : null;
    case 'SELECT_RESULT_ABOVE':
      const decrementedIndex = action.payload.currentIndex - 1 >= 0
        ? action.payload.currentIndex - 1
        : action.payload.currentResults.length - 1;

      return action.payload.currentResults[decrementedIndex];
    case 'SELECT_RESULT_BELOW':
      const incrementedIndex = action.payload.currentIndex + 1 < action.payload.currentResults.length
        ? action.payload.currentIndex + 1
        : 0;

      return action.payload.currentResults[incrementedIndex];
    default:
      return state;
  }
};

export default selectedResultReducer;
