import { Button, Card } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import React from "react";

export default function DetailsCard({ address }) {
  return (
    <Card size="large" id="property-address-card" className="App-property-card" title="Property Details">
      <p>
        <span style={ { fontWeight: 'bold' } }>UPRN </span>
        { address.uprn }
        &nbsp;
        <Button
          type="text"
          shape="circle"
          icon={ <CopyOutlined /> }
          onClick={() => {navigator.clipboard.writeText(address.uprn)}}
        >
        </Button>
      </p>
      <p>{ address.firstLineOfAddress }</p>
      <p>{ address.borough }</p>
      <p>{ address.city }</p>
      <p>{ address.country }</p>
      <p>{ address.postcode }</p>
    </Card>
  );
}
