const searchResultsReducer = (state = [], action) => {
  if (action.type === 'FETCH_SEARCH_RESULTS') {
    const searchResults = action.payload.searchResults;

    return Object.entries(searchResults).map(datum => {
      let listItemNumber = datum[0];
      let propertyData = datum[1];
      return {
        listItemNumber: parseInt(listItemNumber),
        id: propertyData.id,
        address: propertyData.address
      };
    });
  }

  return state;
};

export default searchResultsReducer;
