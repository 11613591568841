import axios from "axios";

import { getBackEndUrl } from "../components/BackEndUrl";

export const updateSearchTerm = (searchTerm) => {
  return {
    type: 'UPDATE_SEARCH_TERM',
    payload: {
      searchTerm: searchTerm
    },
  };
};

export const fetchSearchResults = searchTerm => async (dispatch) => {
  let backendApiUrl = getBackEndUrl();

  const response = await axios.get(backendApiUrl + '/search?q=' + searchTerm);

  return dispatch({
    type: 'FETCH_SEARCH_RESULTS',
    payload: {
      searchResults: response.data,
    },
  });
};

export const selectResultAbove = (currentIndex, currentResults) => {
  return {
    type: 'SELECT_RESULT_ABOVE',
    payload: {
      currentIndex,
      currentResults,
    },
  };
};

export const selectResultBelow = (currentIndex, currentResults) => {
  return {
    type: 'SELECT_RESULT_BELOW',
    payload: {
      currentIndex,
      currentResults,
    },
  };
};
