import { combineReducers } from "redux";
import searchTermReducer from "./searchTermReducer";
import searchResultsReducer from "./searchResultsReducer";
import selectedResultReducer from "./selectedResultReducer";

export default combineReducers({
  searchTerm: searchTermReducer,
  searchResults: searchResultsReducer,
  selectedResult: selectedResultReducer,
});
