const searchTermReducer = (searchTerm = '', action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_TERM':
      return action.payload.searchTerm;
    default:
      return searchTerm;
  }
};

export default searchTermReducer;
