import { Card } from "antd";
import React from "react";

export default function BlockAndBuildingInfoCard({ propertyUprn, blockInfo }) {
  const blockUprn = blockInfo.blockUprn;
  const blockProperties = blockInfo.blockProperties;

  const propertyRows = blockProperties.map(property => {
    const rowText = property.uprn === propertyUprn
      ? <td><span style={{ fontWeight: 'bold' }}>{ property.uprn } - { property.single_line_address }</span></td>
      : <td>{ property.uprn } - { property.single_line_address }</td>;

    return (
      <tr key={ property.uprn }>
        <td/>
        <td>{ rowText }</td>
      </tr>);
  });

  return (<Card size="large" id="property-building-info-card" className="App-property-card" title="Other properties in the same building">
    <table>
      <tbody>
      <tr key={ blockUprn }>
        <td><span style={ { fontWeight: 'bold' } }>Block UPRN</span></td>
        <td>{ blockUprn }</td>
      </tr>
      <tr key="PropertiesHeader">
        <td><span style={ { fontWeight: 'bold' } }>Properties</span></td>
        <td/>
      </tr>
      { propertyRows }
      </tbody>
    </table>
  </Card>);
}
