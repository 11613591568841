import Results from "./Results";
import Input from "./Input";
import React from "react";

function Search() {
    return (
      <div className="App-search">
        <header className="App-search-header">
          <img src="./logo.png" alt="What's my UPRN" width="50%" />
        </header>
        <div>
          <div className="App-search-input">
            <Input />
          </div>
          <div className="App-search-results">
            <Results />
          </div>
        </div>
      </div>
    );
}

export default Search;
