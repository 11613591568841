export function getBackEndUrl() {
  let backendApiUrl = '';
  switch (process.env.NODE_ENV) {
    case 'production':
      backendApiUrl = 'https://api.whatsmyuprn.com';
      break;
    case 'development':
      backendApiUrl = 'http://localhost:8000';
      break;
    default:
      backendApiUrl = 'http://localhost:8000';
  }

  return backendApiUrl;
}
