import '../App.css';
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Search from "./search/Search";
import Property from './property/Property';

function App() {
  return (
    <div className="App">
        <div className="container">
          <div className="App-body">
            <Router>
              <div>
                <Routes>
                  <Route path="/" element={ <Search /> } />
                  <Route path="/property/:id" element={ <Property /> } />
                </Routes>
              </div>
            </Router>
          </div>
        </div>
    </div>
  );
}

export default App;
