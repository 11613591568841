import { Card } from "antd";
import React from "react";

export default function AreaInfoCard({ areaInfo }) {
  return (<Card size="large" id="property-area-info-card" className="App-property-card" title="Area Info">
    <table>
      <tbody>
      <tr>
        <td><span style={ { fontWeight: 'bold' } }>Local Authority</span></td>
        <td>{ areaInfo.localAuthority }</td>
      </tr>
      <tr>
        <td><span style={ { fontWeight: 'bold' } }>Ward</span></td>
        <td>{ areaInfo.ward }</td>
      </tr>
      <tr>
        <td><span style={ { fontWeight: 'bold' } }>Constituency</span></td>
        <td>{ areaInfo.constituency }</td>
      </tr>
      <tr>
        <td><span style={ { fontWeight: 'bold' } }>Region</span></td>
        <td>{ areaInfo.region }</td>
      </tr>
      <tr>
        <td><span style={ { fontWeight: 'bold' } }>Country</span></td>
        <td>{ areaInfo.country }</td>
      </tr>
      </tbody>
    </table>
  </Card>);
}
